import { useState} from 'react';

import { Link } from 'react-router-dom';
import FormInput from '../form-input/form-input.component';
import Button from '../button/button.component'; 

import AuthService from '../../auth/auth_user_utils';


import { 
  SignInContainer,
  ButtonsContainer,
} from  './sign-in.styles';

const defaultFormFields = {
  email: '',
  password: '',
};

const SignInForm = () => {
  const [err,setErr] = useState('')
  const [formFields, setFormFields] = useState(defaultFormFields);
  const { email, password } = formFields;

  const resetFormFields = () => {
    setFormFields(defaultFormFields);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    AuthService.login(formFields.email,formFields.password)
    .then(res=>{
      if(res==200){
        window.location.href="/admin-page-restricted"
      }else{
        setErr("Invalid: Kindly try log in again")
      }
    })

    // try{
    //   resetFormFields();
    //   console.log(auth_user_utils.login(formFields))
    //   // setErr(response)
    //   // if(res.status==200){
    //   //   window.location.href="/admin-page-restricted"
    //   // }else{
    //   //   setErr(res)
    //   // }

    // } catch (error) {
    //   switch (error.code) {
    //     case 'auth/wrong-password':
    //       alert('incorrect password for email');
    //       break;
    //     case 'auth/user-not-found':
    //       alert('no user associated with this email');
    //       break;
    //     default:
    //       console.log(error);
    //   }
    // }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    setFormFields({ ...formFields, [name]: value });
  };

  return (
    <SignInContainer>
      <h2>Login to your Account</h2>
      <span>See registered objects</span>
      <form onSubmit={handleSubmit}>
        <FormInput
          label='Email'
          type='email'
          required
          onChange={handleChange}
          name='email'
          value={email}
        />

        <FormInput
          label='Password'
          type='password'
          required
          onChange={handleChange}
          name='password'
          value={password}
        />
        <Link className='link' to='/forgot-password'>Forgot Password?</Link>
        <ButtonsContainer>
          <Button buttonType='signInUp' type='submit'>Sign In</Button>
        </ButtonsContainer>
        {err&&err.length!==0?err:''}
      </form>
    </SignInContainer>
  );
};

export default SignInForm;