import React from 'react';
import './App.css';
import  {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import {RouteGuard} from './auth/auth_user_utils';

//import Nav from  './components/navigation/navigation.component'
import Home from './routes/home/home.component'
import SpaceObjects from './routes/spaceObjects/spaceObjects.component'
import SingleProject from './templates/projects/SingleProject.component';
import RegisterObject from './routes/addObjects/addObjects.component'
import SignIn from './routes/SignIn/signIn.component'
import AdminPage from './routes/admin/adminPage.component'
import UpdateProjects from './routes/updateObjects/updateObjects.component';

const App = () => {
  return (
        <Router>
          <Routes>
              <Route path='/' index element={<Home/>}/>
              <Route path='/space-objects' element={<SpaceObjects/>}/>
              <Route path='/space-objects/:name' element={<SingleProject/>}/>
              <Route path='/register-space-object' element={<RegisterObject/>}/>
              <Route path='/admin-signin' element={<SignIn/>}/>
              <Route path='/admin-page-restricted' element={<AdminPage/>}/>
              <Route path='/admin-page-restricted/project/:name' element={<UpdateProjects/>}/>
          </Routes>
        </Router>
  );
};


export default App;
