import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import Footer from '../../components/footer/footer.component'

import NavBar from '../../components/navbar/mainNav.component'

import ObjectCard from '../../components/object-card-admin/objectCard.component'
import { ObjectsContainer, Heading } from './adminPage.styles'

import AuthService from '../../auth/auth_user_utils'

const SearchResults = ({data,search})=>{ 
  let Arr = []
  {Array.isArray(data)&&data.map((item,i)=>{
    if(item.function&&item.function.includes(search)||item.name&&item.name.includes(search)||item.summary&&item.summary.includes(search)||item.owner&&item.owner.includes(search)){
      Arr.push(<ObjectCard key={i} data={item} />)
    }
  })}
  // function,name,summary,owner
  return(Arr.length>0?Arr:'No Results')
}

const ProjectData = ({data})=>{
  let Arr = []
  {Array.isArray(data)&&data.map((item,i)=>{
    Arr.push(<ObjectCard key={i} data={item} />)
  })}
  return(Arr)
}

const SearchBar = styled.input`
    position: absolute;
    top: -50px;
    right: 50px;
    padding: 10px;
  `

const Projects = () => {
    const [user,setUser] = useState()

    const [search,setSearch] = useState()
    const [projects,setProjects] = useState()

    useEffect(()=>{
        if(AuthService.getCurrentUser()){
          setUser(AuthService.getCurrentUser())
        }else{
          window.location.href="/admin-signin"
        }

        fetch("/api/admin-projects/",
          {
            methods: "GET",
            headers:{
                'Content-Type': 'application/json',
                'Authorization': AuthService.getCurrentUser()
            }
          }
        )
        .then(async response => {
            const data = await response.json();

            // check for error response
            if (!response.ok) {
                // get error message from body or default to response statusText
                const error = (data && data.message) || response.statusText;
                console.log(error)
                return Promise.reject(error);
            }
            setProjects(data['data'])
        })
        .catch(error => {
            // this.setState({ errorMessage: error.toString() });
            console.error('There was an error!', error);
        });
    },[])

  return (
    <div>
      <NavBar isLoggedIn={user?true:false} user='user' mode='light'/>
      <Heading>Registered Space Objects</Heading>
      <ObjectsContainer>
        <SearchBar placeholder="Search" value={search} onChange={(e)=>setSearch(e.target.value)}/>
        {search&&search.length>0?<SearchResults data={projects} search={search}/>:<ProjectData data={projects}/>}  
      </ObjectsContainer>
      <Footer bottom='relative'/>
    </div>
  )
}
export default Projects
