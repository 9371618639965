import styled from 'styled-components'

export const BackgroundImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 60%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-image: ${({ imageUrl }) => `url(${imageUrl})`};
  z-index: 1;
`

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 2;
  height: 100%;
  width: 60%;
  background: rgba(0,0,0,0.7);
  h2{
    margin-left: 20px;
    color: white;
  }
  span{
    margin-left: 20px;
    color: white;
  }
`

export const ObjectCardContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  width: 80vw;
  height: 100px;
  margin: 20px;
  align-items: center;

  button{
    height: 50px;
    margin-left: 10px;
  }
`

