import React, { useEffect, useState } from 'react'
import { Fragment} from 'react'
import { Outlet } from 'react-router-dom'
import styled from 'styled-components'

import { Link } from 'react-router-dom'
import logo from '../../assets/logo.png'
import {
  NavigationContainer,
  NavLinks,
  LogoContainer
} from './mainNav.styles.jsx'

import AuthService from '../../auth/auth_user_utils'

const MainMenu = (props) => {

  const [user,setUser] = useState()

  useEffect(()=>{
    setUser(AuthService.getCurrentUser())  
  },[])
  
  //props mode defines color modes. Dark or Light mode. This should also depend on thegeneral format of the screens contrast
  if(user){
    return (
        <Fragment>
          <NavigationContainer>
            <LogoContainer>
              <img src={logo} alt='logo' />
            </LogoContainer>
            <NavLinks className={props.mode}>
                    <Link className='link' to='/'> Home </Link>
                    <Link className='link' to='/register-space-object'> Register a Space Object</Link>
                    <Link className='link' to='/space-objects'>Published Space Objects</Link>
                    <Link className='link' to='/admin-page-restricted'>Admin Page</Link>
                    <button onClick={()=>AuthService.logout()} className='btn-menu'>Log Out</button>
            </NavLinks>
          </NavigationContainer>
          <Outlet />
        </Fragment>
      )
  }else{
    return (
      <Fragment>
        <NavigationContainer>
          <LogoContainer>
            <img src={logo} alt='logo' />
          </LogoContainer>
          <NavLinks className={props.mode}>
                  <Link className='link' to='/'> Home </Link>
                  <Link className='link' to='/register-space-object'> Register a Space Object</Link>
                  <Link className='btn-menu' to='/space-objects'>Browse Space Objects</Link>
          </NavLinks>
        </NavigationContainer>
        <Outlet />
      </Fragment>
    )
  }
}

export default MainMenu
