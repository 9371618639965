import {jsPDF} from 'jspdf'
import autoTable from 'jspdf-autotable'
import KSALogo from '../../assets/logo.png'

const API_URL = "/api";

export function generateFullPDF(data){
  
  // Create a new jsPDF instance
  var doc = new jsPDF();
  // Get the width and height of the PDF document
  var pdfWidth = doc.internal.pageSize.getWidth();
  var pdfHeight = doc.internal.pageSize.getHeight();

  var HeaderTitle = "Kenya Space Objects Registry"
  // Load your logo image
  var HeaderLogo = new Image();
  HeaderLogo.src = KSALogo;
  
  // Add the logo to the document
  doc.addImage(HeaderLogo, "PNG", 5, 5, 22, 17);

  // Add the title to the document
  doc.setFontSize(12);
  doc.setFont("helvetica", "bold");
  doc.text(120, 13, HeaderTitle);

  /** 
   * 
   * Section 1
   * 
   */
  
  // Define the title and paragraph text
  var title = "Background";
  var paragraph = data["summary"] 

  // Load the image
  var HeroImg = new Image();
  HeroImg.src = `${API_URL}/static/images/object/64634029dad0efc485814d51.png`;

  // Wait for the image to load

  // Set the position and dimensions of the title
  var titleX = 20;
  var titleY = 40;
  
  // Set the position and dimensions of the paragraph
  var paragraphX = 10;
  var paragraphY = titleY + 10;
  var paragraphMaxWidth = 140;
  
  // Set the position and dimensions of the image
  var imageX = doc.internal.pageSize.getWidth() - 100;
  var imageY = titleY;
  var imageWidth = 80;
  var imageHeight = 60;

  // Add the title to the PDF
  doc.setFontSize(14);
  doc.text(titleX, titleY, title);
  // Add a header to each page
  doc.setDrawColor(0);
  doc.setLineWidth(0.3); // Border width
  doc.setFontSize(14);
  
  // Add a border around the logo
  doc.rect(imageX-2, imageY-2, imageWidth+4, imageHeight+10);


  // Split the paragraph text into multiple lines based on the maximum width
  var lines = doc.splitTextToSize(paragraph, paragraphMaxWidth);
  
  // Add each line of the paragraph to the PDF
  doc.setFontSize(10);

  doc.setFont("helvetica", "normal");
  doc.text(paragraphX, paragraphY, lines);

  // Add the image to the PDF
  doc.addImage(HeroImg, 'png', imageX, imageY, imageWidth, imageHeight);
  // Add the text label below the logo
  doc.setFontSize(10);
  doc.text(imageX, imageY+imageHeight+6, data["owner"]);

  /*

    Section 2

  */

  // Define the subtitle and paragraph content
  var subtitle1 = "Space Object Details";
  var paragraph1 = "Gain comprehensive insights into the specifications, mission objectives, and orbital characteristics of various satellites, allowing you to explore their size, weight, technology, scientific instruments, and their significant contributions in areas such as communications, Earth observation, and scientific research.";
  
  // Center align the subtitle
  var subtitle1X = 20;
  var subtitle1Y = paragraphY + 80 ;
  
  // Set the position of the paragraph
  var paragraph1X = 10;
  var paragraph1Y = subtitle1Y + 10;

  // Add the subtitle to the document
  doc.setFontSize(16);
  doc.setFont("helvetica", "bold");
  doc.text(subtitle1X, subtitle1Y, subtitle1);

  // Add the paragraph to the document
  doc.setFontSize(12);
  doc.setFont("helvetica", "normal");
  // Split the paragraph text into multiple lines based on the maximum width
  var lines = doc.splitTextToSize(paragraph1, pdfWidth - 40);
  doc.text(paragraph1X, paragraph1Y, lines);

  // Create an array to hold the table data
  var tableData = [
    ['SPace Object Name',`${data["soName"]}`],
    ['Space Object Status',`${data["soStatus"]}`],
    ['Nation','Kenya'],
    ['Owner',`${data["owner"]}`],
    ['Operator',`${data["operator"]}`],
    ['Mission Type/Application',`${data["purpose"]}`],
    ['Cospar No',`${data["cospar"]}`],
    ['Registration Status',`${data["regStatus"]}`],
    ['Catalogue No',`${data["ctNo"]}`],
    ['Mass',`${data["mass"]}`],
    ['Propulsion',`${data["propulsion"]}`],
    ['Power',`${data["power"]}`]
  ];

  // Set the initial position of the table
  var tableX = 20;
  var tableY = paragraph1Y+ 20;

  // Set the column widths
  var columnWidth1 = 60;
  var columnWidth2 = 110;

  // Set border styles
  var borderWidth = 0.5;
  var borderColor = "#000000";

  // Loop through the table data and add it to the document
  for (var j = 0; j < tableData.length; j++) {
    var row = tableData[j];
    
    // Draw borders for each cell
    doc.setLineWidth(borderWidth);
    doc.setDrawColor(borderColor);
    doc.rect(tableX, tableY, columnWidth1, 10, "S");
    doc.rect(tableX + columnWidth1, tableY, columnWidth2, 10, "S");
    
    // Add content to each cell
    doc.text(tableX + 2, tableY + 7, row[0]);
    doc.text(tableX + columnWidth1 + 2, tableY + 7, row[1]);
    
    tableY += 10;
  }

  // Add a new page
  doc.addPage();

  /*

    Section 3

  */

  // Define the subtitle and paragraph content
  var subtitle1 = "Launch Details";
  var paragraph1 = "The table below share information about space mission, including launch dates, rocket types, payloads, and mission objectives. Explore the exciting realm of space exploration as you delve into the specifics of each launch, from the launch vehicle utilized to the destination and purpose of the mission. Stay informed about the latest advancements and achievements in space travel with Launch Details, your go-to source for the captivating stories behind every successful launch.";
  
  // Center align the subtitle
  var subtitle1X = 20;
  var subtitle1Y = 20 ;
  
  // Set the position of the paragraph
  var paragraph1X = 10;
  var paragraph1Y = 30;

  // Add the subtitle to the document
  doc.setFontSize(16);
  doc.setFont("helvetica", "bold");
  doc.text(subtitle1X, subtitle1Y, subtitle1);

  // Add the paragraph to the document
  doc.setFontSize(12);
  doc.setFont("helvetica", "normal");
  // Split the paragraph text into multiple lines based on the maximum width
  var lines = doc.splitTextToSize(paragraph1, pdfWidth - 40);
  doc.text(paragraph1X, paragraph1Y, lines);

  // Create an array to hold the table data
  var tableData = [
    ['Launching State',`${data["launchState"]}`],
    ['Launch Date',`${data["launchDate"]}`],
    ['Launch Contractor',`${data["launchContractor"]}`],
    ['Launch Location',`${data["launchLocation"]}`],
    ['Launch Vehicle',`${data["launchVehicle"]}`],
  ];

  // Set the initial position of the table
  var tableX = 20;
  var tableY = paragraph1Y+ 30;

  // Set the column widths
  var columnWidth1 = 60;
  var columnWidth2 = 110;

  // Set border styles
  var borderWidth = 0.5;
  var borderColor = "#000000";

  // Loop through the table data and add it to the document
  for (var j = 0; j < tableData.length; j++) {
    var row = tableData[j];
    
    // Draw borders for each cell
    doc.setLineWidth(borderWidth);
    doc.setDrawColor(borderColor);
    doc.rect(tableX, tableY, columnWidth1, 10, "S");
    doc.rect(tableX + columnWidth1, tableY, columnWidth2, 10, "S");
    
    // Add content to each cell
    doc.text(tableX + 2, tableY + 7, row[0]);
    doc.text(tableX + columnWidth1 + 2, tableY + 7, row[1]);
    
    tableY += 10;
  }

  /*

    Section 4

  */

  // Define the subtitle and paragraph content
  var subtitle1 = "Orbital Parameters";
  var paragraph1 = "These Orbital Parameters provides essential data on the fundamental characteristics that define the paths followed by satellites in space. Explore key parameters including inclination, eccentricity, apogee, perigee, and orbital period to gain insights into the unique trajectories and behaviors of satellites. Discover how these parameters influence the altitude, speed, and coverage of satellite orbits";
  
  // Center align the subtitle
  var subtitle1X = 20;
  var subtitle1Y = 130 ;
  
  // Set the position of the paragraph
  var paragraph1X = 10;
  var paragraph1Y = subtitle1Y + 10;

  // Add the subtitle to the document
  doc.setFontSize(16);
  doc.setFont("helvetica", "bold");
  doc.text(subtitle1X, subtitle1Y, subtitle1);

  // Add the paragraph to the document
  doc.setFontSize(12);
  doc.setFont("helvetica", "normal");
  // Split the paragraph text into multiple lines based on the maximum width
  var lines = doc.splitTextToSize(paragraph1, pdfWidth - 40);
  doc.text(paragraph1X, paragraph1Y, lines);

  // Create an array to hold the table data
  var tableData = [
    ['Orbital Position',`${data["orbitalPosition"]}`],
    ['Nodal Period',`${data["nodal"]}`],
    ['Inclination',`${data["inclination"]}`],
    ['Apogee',`${data["apogee"]}`],
    ['Perigee',`${data["perigee"]}`]
  ];

  // Set the initial position of the table
  var tableX = 20;
  var tableY = paragraph1Y + 25;

  // Set the column widths
  var columnWidth1 = 60;
  var columnWidth2 = 110;

  // Set border styles
  var borderWidth = 0.5;
  var borderColor = "#000000";

  // Loop through the table data and add it to the document
  for (var j = 0; j < tableData.length; j++) {
    var row = tableData[j];
    
    // Draw borders for each cell
    doc.setLineWidth(borderWidth);
    doc.setDrawColor(borderColor);
    doc.rect(tableX, tableY, columnWidth1, 10, "S");
    doc.rect(tableX + columnWidth1, tableY, columnWidth2, 10, "S");
    
    // Add content to each cell
    doc.text(tableX + 2, tableY + 7, row[0]);
    doc.text(tableX + columnWidth1 + 2, tableY + 7, row[1]);
    
    tableY += 10;
  }

    // Save the PDF document
    doc.save(`Full Report - ${data["soName"]}.pdf`);


}

export function generateSummaryPDF(data){
  // Create a new jsPDF instance
  var doc = new jsPDF();

  var HeaderTitle = "Kenya Space Objects Registry"
  // Load your logo image
  var HeaderLogo = new Image();
  HeaderLogo.src = KSALogo;

  // Add the logo to the document
  doc.addImage(HeaderLogo, "PNG", 5, 5, 22, 17);

  // Add the title to the document
  doc.setFontSize(12);
  doc.text(120, 13, HeaderTitle);

  // Define the title and paragraph content
  var title = "Summary Report: " + data["soName"];
  var paragraph = "A satellite mission involves the deployment of a purpose-built spacecraft into space to achieve specific scientific, communication, or observational objectives. These missions require careful consideration of technical specifications to ensure optimal performance. Satellite missions encompass key aspects such as the design and construction of the satellite, selection of appropriate sensors and instruments, determination of orbital parameters, and establishment of communication protocols. Technical specifications encompass factors like satellite dimensions, mass, power requirements, payload capacity, data transmission capabilities, and onboard processing capabilities.";

  // Set font styles
  doc.setFontSize(18);
  doc.setFont("helvetica", "bold");

  // Add the title to the document
  doc.text(20, 35, title);

  // Set font styles for the horizontal line
  doc.setLineWidth(1);
  doc.setDrawColor(0, 0, 0);

  // Add a horizontal line below the title
  doc.line(20, 38, 190, 38);

  // Set font styles for the paragraph
  doc.setFontSize(12);
  doc.setFont("helvetica", "normal");

  // Parargraph width
  var paragraphMaxWidth = 150
  // Split the paragraph text into multiple lines based on the maximum width
  var lines = doc.splitTextToSize(paragraph, paragraphMaxWidth);

  // Add the paragraph to the document
  doc.text(20, 48, lines);

  // Create an array to hold the table data
  var tableData = [
    ['Nation','Kenya'],
    ['Owner',`${data["owner"]}`],
    ['Operator',`${data["operator"]}`],
    ['Mission Type/Application',`${data["purpose"]}`],
    ['Catalogue No',`${data["ctNo"]}`],
    ['Mass',`${data["mass"]}`],
    ['Propulsion',`${data["propulsion"]}`],
    ['Power',`${data["power"]}`],
    ['Launching State',`${data["launchState"]}`],
    ['Launch Date',`${data["launchDate"]}`],
    ['Launch Contractor',`${data["launchContractor"]}`],
    ['Launch Location',`${data["launchLocation"]}`],
    ['Launch Vehicle',`${data["launchVehicle"]}`],
    ['Orbital Position',`${data["orbitalPosition"]}`],
    ['Nodal Period',`${data["nodal"]}`],
    ['Inclination',`${data["inclination"]}`],
    ['Apogee',`${data["apogee"]}`],
    ['Perigee',`${data["perigee"]}`],
    ['Status',`${data["soStatus"]}`],
  ];

  // // Generate the table data
  // for (var i = 0; i < 20; i++) {
  //   var rowData = [
  //     "Row " + (i + 1) + ", Column 1",
  //     "Row " + (i + 1) + ", Column 2"
  //   ];
  //   tableData.push(rowData);
  // }

  // Set font styles for the table
  doc.setFontSize(12);
  doc.setFont("helvetica", "normal");

  // Set the initial position of the table
  var tableX = 20;
  var tableY = 90;

  // Set the column widths
  var columnWidth1 = 60;
  var columnWidth2 = 110;

  // Set border styles
  var borderWidth = 0.5;
  var borderColor = "#000000";

  // Loop through the table data and add it to the document
  for (var j = 0; j < tableData.length; j++) {
    var row = tableData[j];
    
    // Draw borders for each cell
    doc.setLineWidth(borderWidth);
    doc.setDrawColor(borderColor);
    doc.rect(tableX, tableY, columnWidth1, 10, "S");
    doc.rect(tableX + columnWidth1, tableY, columnWidth2, 10, "S");
    
    // Add content to each cell
    doc.text(tableX + 2, tableY + 7, row[0]);
    doc.text(tableX + columnWidth1 + 2, tableY + 7, row[1]);
    
    tableY += 10;
  }

  // Output the document
  doc.save(`Summary Report - ${data["soName"]}.pdf`);
}

export function generateSummaryAllPDF(data){
  console.log(data)
  // Create a new JSPDF instance
  var doc = new jsPDF('landscape');
  // Set the page title
  var pageTitle = 'Kenya Space Objects Register Summary';
  var pageWidth = doc.internal.pageSize.getWidth();
  var pageTitleWidth = doc.getStringUnitWidth(pageTitle) * doc.internal.getFontSize() / doc.internal.scaleFactor;
  var pageTitleX = (pageWidth - pageTitleWidth) / 2;
  doc.setFontSize(18);
  doc.text(pageTitle, pageTitleX, 20);

  // Calculate paragraph dimensions
  var paragraph = 'A concise overview of the information and data related to space objects registered in Kenya.';
  var paragraphWidth = doc.getStringUnitWidth(paragraph) * doc.internal.getFontSize() / doc.internal.scaleFactor;
  var paragraphX = (pageWidth - paragraphWidth) / 2;
  doc.setFontSize(12);
  doc.text(paragraph, paragraphX, 30);


  // Add a logo to the top right corner
  var HeaderLogo = new Image();
  HeaderLogo.src = KSALogo;
  var logoWidth = 20;
  var logoHeight = 15;
  var logoX = doc.internal.pageSize.width - logoWidth - 10;
  var logoY = 5;
  doc.addImage(HeaderLogo, 'PNG', logoX, logoY, logoWidth, logoHeight);

  var dataKeys = ['cospar', 'soName', 'owner', 'purpose', 'launchDate', 'launchLocation', 'launchVehicle', 'nodal', 'inclination', 'apogee', 'perigee', 'inclination', 'regStatus', 'soStatus']
  // Define table properties
  var tableData = [];
  var columnCount = dataKeys.length;
  var rowCount = data?.length;
  var columnWidth = 15;
  var rowHeight = 10;
  var startY = 40;
  
  if(data){
  // Generate table data
    for (var i = 0; i < rowCount; i++) {
      var rowData = [];
      for (var j = 0; j < columnCount; j++) {
          rowData.push(data[i][[dataKeys[j]]]);
      }
      tableData.push(rowData);
    }
  }
    
  // Add table to the document using jspdf-autotable plugin
  doc.autoTable({
    head: [['International Identification', 'Space Object Name', 'Owner', 'General Function', 'Launch Date', 'Launch Site','Launch Vehicle', 'Period', 'Inclination', 'Apogee', 'Perigee', 'Inclination','Registration Status', 'Space Object Status']],
    body: tableData,
    startY: startY,
    theme: 'grid',
    // columnStyles: { 0: { cellWidth: columnWidth } }, // Adjust column width if needed
    styles: { fontSize: 10, cellPadding: 1, overflow: 'linebreak' },
    headStyles: { fillColor: [40, 40, 40] }, // Set header background color
  });
  // Output the document
  doc.save(`Summary Report - Kenya Space Objects.pdf`);
}