import {useEffect, useState} from 'react'
import { useParams } from "react-router-dom";
import KSALogo from '../../assets/logo.png'
import { 
  ReportButtonContainer,
  ReportContainer,
  Header,
  ReportFull,
  HeaderSection,
  ContentSection,
  SummarySection
} from "./singleProject.style";

import NavBar from '../../components/navbar/mainNav.component'

import { generateFullPDF, generateSummaryPDF  } from './generateReportPdf'

const API_URL = "/api";

function SingleProject() {

    let [projectData,setProjectData] = useState()
    // let { url } = useRouteMatch();
    let {name} = useParams()
    useEffect(()=>{
      // GET request using fetch inside useEffect React hook
      fetch(`${API_URL}/projects/${name}/`)
        .then(response => response.json())
        .then(data => setProjectData(data.data));
    },[name])
    
    return (
      <div style={{paddingTop: '20vh'}}>
        <NavBar user='test' isLoggedIn='true' mode='light'/>
        
        <ReportButtonContainer>
          <h2>Space Object Report Navigation</h2>
          <div>
            <button onClick={()=>window.location="#fullReport"}>View Full report section</button>
            <button onClick={()=>generateFullPDF(projectData)}>Export PDF Full report</button>
          </div>
          <div>
            <button onClick={()=>window.location="#summaryReport"}>View Mission Summary</button>
            <button onClick={()=>generateSummaryPDF(projectData)}>Export PDF Mission Summary</button>
          </div>
        </ReportButtonContainer>

        <ReportContainer>
          <Header>
            <h1> Kenya Space Object Registry </h1>
            <img src={KSALogo} alt="" />
          </Header>
          <ReportFull>
            <h2 id="fullReport" className="title">{projectData&&projectData.soname}</h2>
            <HeaderSection>
              <div className="details">
                <h3>Background</h3>
                <p>
                {/* {JSON.stringify(projectData)}  */}
                {projectData&&projectData.summary}
                </p>
              </div>
              <div className="image">
                <img src={projectData&&`${API_URL}/static/images/object/${projectData._id}.png`} alt="" />
                <span>{projectData&&projectData.soName} ({projectData&&projectData.owner})</span>
              </div>
            </HeaderSection>

            <ContentSection>
              <h3>Space Object Details</h3>
              <p>
                Gain comprehensive insights into the specifications, mission objectives, and orbital characteristics of various satellites, allowing you to explore their size, weight, technology, scientific instruments, and their significant contributions in areas such as communications, Earth observation, and scientific research
              </p>

              <table>
                <tbody className="tableFull">
                  <tr>
                    <td>Space Object Name:</td>
                    <td>{projectData&&projectData.soName}</td>
                  </tr>
                  <tr>
                    <td>Nation:</td>
                    <td>Kenya</td>
                  </tr>
                  <tr>
                    <td>Owner:</td>
                    <td>{projectData&&projectData.owner}</td>
                  </tr>
                  <tr>
                    <td>Website:</td>
                    <td>{projectData&&projectData.ownerWebsite}</td>
                  </tr>
                  <tr>
                    <td>Status:</td>
                    <td>{projectData&&projectData.soStatus}</td>
                  </tr>
                  <tr>
                    <td>Operator:</td>
                    <td>{projectData&&projectData.operator}</td>
                  </tr>
                  <tr>
                    <td>Mission Type / Application:</td>
                    <td>{projectData&&projectData.purpose}</td>
                  </tr>
                  <tr>
                    <td>Catalogue No:</td>
                    <td>{projectData&&projectData.ctNo}</td>
                  </tr>
                  <tr>
                    <td>International Identifier / COSPAR No:</td>
                    <td><a href={projectData&&projectData.unoosaLink} target='_blank'>{projectData&&projectData.cospar}</a> </td>
                  </tr>
                  <tr>
                    <td>Registration Status:</td>
                    <td>{projectData&&projectData.regStatus}</td>
                  </tr>
                  <tr>
                    <td>Mass:</td>
                    <td>{projectData&&projectData.mass}</td>
                  </tr>
                  <tr>
                    <td>Propulsion:</td>
                    <td>{projectData&&projectData.propulsion}</td>
                  </tr>
                  <tr>
                    <td>Power:</td>
                    <td>{projectData&&projectData.power}</td>
                  </tr>
                </tbody>
              </table>
            </ContentSection>

            <ContentSection>
              <h3>Launch Details</h3>
              <p>
                The table below share information about space mission, including launch dates, rocket types, payloads, and mission objectives. Explore the exciting realm of space exploration as you delve into the specifics of each launch, from the launch vehicle utilized to the destination and purpose of the mission. Stay informed about the latest advancements and achievements in space travel with Launch Details, your go-to source for the captivating stories behind every successful launch. 
              </p>

              <table>
                <tbody className="tableFull">
                  <tr>
                    <td>Launching State: </td>
                    <td>{projectData&&projectData.launchState}</td>
                  </tr>
                  <tr>
                    <td>Launch Date: </td>
                    <td>{projectData&&projectData.launchDate}</td>
                  </tr>
                  <tr>
                    <td>Launch Contractor: </td>
                    <td>{projectData&&projectData.launchContractor}</td>
                  </tr>
                  <tr>
                    <td>Launch Location: </td>
                    <td>{projectData&&projectData.launchLocation}</td>
                  </tr>
                  <tr>
                    <td>Launch Vehicle: </td>
                    <td>{projectData&&projectData.launchVehicle}</td>
                  </tr>
                </tbody>
              </table>

            </ContentSection>


            <ContentSection>
              <h3>Orbital Parameters</h3>
              <p>
                These Orbital Parameters provides essential data on the fundamental characteristics that define the paths followed by satellites in space. Explore key parameters including inclination, eccentricity, apogee, perigee, and orbital period to gain insights into the unique trajectories and behaviors of satellites. Discover how these parameters influence the altitude, speed, and coverage of satellite orbits
              </p>

              <table>
                <tbody className="tableFull">
                  <tr>
                    <td>Orbital Position:</td>
                    <td>{projectData&&projectData.orbitalPosition}</td>
                  </tr>
                  <tr>
                    <td>Nodal Period:</td>
                    <td>{projectData&&projectData.nodal}</td>
                  </tr>
                  <tr>
                    <td>Inclination:</td>
                    <td>{projectData&&projectData.inclination}</td>
                  </tr>
                  <tr>
                    <td>Apogee:</td>
                    <td>{projectData&&projectData.apogee}</td>
                  </tr>
                  <tr>
                    <td>Perigee:</td>
                    <td>{projectData&&projectData.perigee}</td>
                  </tr>
                </tbody>
              </table>
              
            </ContentSection>
          </ReportFull>
        </ReportContainer>

        <ReportContainer>

        <SummarySection>
              <Header>
                <h1> Kenya Space Object Registry </h1>
                <img src={KSALogo} alt="" />
              </Header>
              <h3 id="summaryReport">
                Summary Report: {projectData&&projectData.soName}
              </h3>
              <p>
              A satellite mission involves the deployment of a purpose-built spacecraft into space to achieve specific scientific, communication, or observational objectives. These missions require careful consideration of technical specifications to ensure optimal performance. Satellite missions encompass key aspects such as the design and construction of the satellite, selection of appropriate sensors and instruments, determination of orbital parameters, and establishment of communication protocols. Technical specifications encompass factors like satellite dimensions, mass, power requirements, payload capacity, data transmission capabilities, and onboard processing capabilities. By meticulously defining and adhering to these specifications, satellite missions can deliver accurate and reliable data, enable global connectivity, and advance our knowledge in various fields of study, contributing to scientific breakthroughs and societal advancements.
              </p>

              <table>
                <tbody className="tableFull">
                <tr>
                    <td>Nation:</td>
                    <td>Kenya</td>
                  </tr>
                  <tr>
                    <td>Owner:</td>
                    <td>{projectData&&projectData.owner}</td>
                  </tr>
                  <tr>
                    <td>Website:</td>
                    <td>{projectData&&projectData.ownerWebsite}</td>
                  </tr>
                  <tr>
                    <td>Operator:</td>
                    <td>{projectData&&projectData.operator}</td>
                  </tr>
                  <tr>
                    <td>Status:</td>
                    <td>{projectData&&projectData.soStatus}</td>
                  </tr>
                  <tr>
                    <td>Mission Type / Application:</td>
                    <td>{projectData&&projectData.purpose}</td>
                  </tr>
                  <tr>
                    <td>Cataloge No:</td>
                    <td>{projectData&&projectData.ctNo}</td>
                  </tr>
                  <tr>
                    <td>Registration status:</td>
                    <td>{projectData&&projectData.regStatus}</td>
                  </tr>
                  <tr>
                    <td>COSPAR No:</td>
                    <td>{projectData&&projectData.cospar}</td>
                  </tr>
                  <tr>
                    <td>Mass:</td>
                    <td>{projectData&&projectData.mass}</td>
                  </tr>
                  <tr>
                    <td>Propulsion:</td>
                    <td>{projectData&&projectData.propulsion}</td>
                  </tr>
                  <tr>
                    <td>Power:</td>
                    <td>{projectData&&projectData.power}</td>
                  </tr>
                  <tr>
                    <td>Launching State: </td>
                    <td>{projectData&&projectData.launchState}</td>
                  </tr>
                  <tr>
                    <td>Launch Date: </td>
                    <td>{projectData&&projectData.launchDate}</td>
                  </tr>
                  <tr>
                    <td>Launch Contractor: </td>
                    <td>{projectData&&projectData.launchContractor}</td>
                  </tr>
                  <tr>
                    <td>Launch Location: </td>
                    <td>{projectData&&projectData.launchLocation}</td>
                  </tr>
                  <tr>
                    <td>Launch Vehicle: </td>
                    <td>{projectData&&projectData.launchVehicle}</td>
                  </tr>
                  <tr>
                    <td>Orbital Position:</td>
                    <td>{projectData&&projectData.orbitalPosition}</td>
                  </tr>
                  <tr>
                    <td>Nodal Period:</td>
                    <td>{projectData&&projectData.nodal}</td>
                  </tr>
                  <tr>
                    <td>Inclination:</td>
                    <td>{projectData&&projectData.inclination}</td>
                  </tr>
                  <tr>
                    <td>Apogee:</td>
                    <td>{projectData&&projectData.apogee}</td>
                  </tr>
                  <tr>
                    <td>Perigee:</td>
                    <td>{projectData&&projectData.perigee}</td>
                  </tr>
                </tbody>
              </table>
            </SummarySection>
        </ReportContainer>
      </div>
    );
  }

export default SingleProject