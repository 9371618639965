import styled from 'styled-components'

export const Heading = styled.h1`
  font-size: 3.5rem;
  margin: 12% 0 0 15%;
`

export const ObjectsContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: auto;
  width: 80vw;
  margin: 100px auto;
`