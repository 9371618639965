import styled from "styled-components";

export const ReportButtonContainer = styled.div`
    width: 70%;
    margin: auto;
    text-align: center;
    div{
        display: flex;
        justify-content: center;
        margin: 10px;
        button{
            width: 400px;
            margin: 0px 10px;
            padding: 10px;
        }
    }
`;

export const ReportContainer = styled.div`
    position: relative;
    margin: 10% 8%;
    border: 1px solid gray;
    background: aliceblue;
    padding: 4% 6%;
`
export const Header = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    height: 100px;
    width: 100%;
    margin-top: 25px;   
    img{
        position: absolute;
        top: 10px;
        left: 10px;
        width: 100px;
    }
    h1{
        position: absolute;
        top: 20px;
        right: 10px;
        font-size: large;
    }
`

export const ReportFull = styled.div`
    position: relative;
    width: 100%;

    .title{
        font-weight: 400;
        font-size: 3rem;
        text-align: center;
        border-bottom: 1px solid gray;
        padding-bottom: 20px;
    }
    table, th, td {
        border: 1px solid black;
        border-collapse: collapse;
    }
    .tableFull{
        margin: auto;
        width: 100vh;
        td{
            border: 1px solid black;
            border-collapse: collapse;
            margin: 0;
            padding: 5px;
            padding-left: 20px;
        }
        tr td:first-child{
            font-weight: bold;
            width: 30vw;
        }
        tr td:last-child{
            width: 70vw;
        }
    }
`;

export const HeaderSection = styled.div`
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    padding-bottom: 50px;

    .details{
        width: 45%;

        h3{
            font-size: 2rem;
            margin: 15px;
            margin-left: 20px;
        }
        p{
            margin: 0;
        }
    }

    .image{
        display: flex;
        flex-direction: column;
        width: 50%;
        text-align: center;
        border: 1px solid black;
        padding: 10px;
        img{
            width: 100%;
            margin: auto;
            margin-bottom: 10px;
        }
    }

    @media (max-width: 660px) {
        flex-direction: column;
        .details{
            width: 100%;
        }
        .image{
            width: 100%;
        }
    }
`;

export const ContentSection = styled.div`
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    
    h3{
        margin: 20px;
        margin-left: 50px;
        font-size: 2rem;
    }

    p{
        margin: 0;
        margin-bottom: 40px;
    }
    
    table, th, td {
        border: 1px solid black;
        border-collapse: collapse;
    }
    .tableFull{
        margin: auto;
        width: 100vh;
        td{
            border: 1px solid black;
            border-collapse: collapse;
            margin: 0;
            padding: 5px;
            padding-left: 20px;
        }
        tr td:first-child{
            font-weight: bold;
            width: 30vw;
        }
        tr td:last-child{
            width: 70vw;
        }
    }
`

export const SummarySection = styled.div`
    h3{
        font-weight: 400;
        font-size: 2.5rem;
        text-align: center;
        border-bottom: 1px solid gray;
        padding-bottom: 20px;
    }

    table, th, td {
        border: 1px solid black;
        border-collapse: collapse;
    }
    .tableFull{
        margin: auto;
        width: 100vh;
        td{
            border: 1px solid black;
            border-collapse: collapse;
            margin: 0;
            padding: 5px;
            padding-left: 20px;
        }
        tr td:first-child{
            font-weight: bold;
            width: 30vw;
        }
        tr td:last-child{
            width: 70vw;
        }
    }
`