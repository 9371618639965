import React from 'react';
import NavBar from '../../components/navbar/mainNav.component'
import Footer from '../../components/footer/footer.component'
import hero from '../../assets/hero.jpg'
import {
    HomeContainer,
    MainSection,
    DivContainer,
    ImageContainer,
    DivCircle
} from './home.styles'
import { Link } from 'react-router-dom';


const Home = () => {
    return(
        <HomeContainer>
            <NavBar isLoggedIn={true} user='user' mode='dark'/>
             <MainSection>
                 <DivContainer>
                    <h2>KENYA SPACE AGENCY</h2>
                    <h1>REGISTRY OF OUTER SPACE OBJECTS {process.env.API_URL}</h1>
                    <p>Are you planning to launch an object into space? <Link className='link' to='/register-space-object'>Register Here</Link></p>
                 </DivContainer>
                 <DivCircle></DivCircle>
                 <ImageContainer>
                     <img src={hero} alt="logo"/>
                </ImageContainer>
             </MainSection>

             <Footer bottom='fixed'/>
        </HomeContainer>  
    );
};
export default  Home;