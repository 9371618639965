import styled from 'styled-components'

export const Heading = styled.h1`
  font-size: 3.5rem;
  margin: 12% 0 0 15%;

  @media (max-width: 660px) {
    font-size: 1.5rem;
    margin: 20% 0 0 15%;
  }

`

export const SubHeading = styled.h3`
  font-size: normal;
  margin: 12px 0 0 15%;
`

export const ObjectsContainer = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  height: auto;
  width: 80vw;
  margin: 100px auto;

  h3{
    width: 100%;
    font-weight: 400;
    font-size: large;
    text-align: center;
    border-bottom: 1px solid gray;
    padding-bottom: 10px;
    margin-top: 40px;
  }
  
`


export const SummarySection = styled.div`
    width: 100%; 
    table{
      width: 90%;
    }
    th, td {
        padding: 5px;
    }
    tr:nth-child(1){
      color: black;
      cursor: auto;
      &:hover{
        color: black;
      }
    }
    tr{
      width: 100%;
      cursor: pointer;
      transition: 300ms;
      &:hover{
        color: #3E4197;
      }
    }
    .tableFull{
        margin: auto;
        width: 100vh;
        td{
            margin: 0;
            padding: 5px;
            padding-left: 20px;
        }
    }
`