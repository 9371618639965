import React from 'react'
import styled from 'styled-components'
import {FaCopyright,FaChevronCircleUp} from 'react-icons/fa'

//import { Link } from 'react-router-dom'


const Footer = (props) => {

    
    
    const FooterContainer = styled.div`
        position: ${props.bottom};
        left: 0;
        bottom: 0;
        height:10vh;
        width:100vw;
        display:flex;
        flex-direction:row;
        justify-content: center;
        align-items: center;
        p{
        margin: 0 auto 0 5px;
        }
    `;
    const Copyright = styled(FaCopyright)`
        color:#3E4197;
        size:80px;
        margin-left: 20px;
    `;
    const Chevron = styled(FaChevronCircleUp)`
        color:#3E4197;
        size:80px;
        margin-right: 20px;
    
    `;

    return (
        <FooterContainer>
            <Copyright/><p>2022 All Rights Reserved</p>
            <Chevron/>
        </FooterContainer>

    )
}


export default Footer;