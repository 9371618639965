import { useNavigate } from 'react-router-dom'

import {
  BackgroundImage,
  Body,
  ObjectCardContainer
} from './objectCard.styles'

const API_URL = "/api";

const ProjectCard = ({ data }) => {
  const { soName, _id, summary, operator } = data;
  const navigate = useNavigate();

  const onNavigateHandler = () => navigate(`/space-objects/${_id}` );

  function reduceSentenceToWords(sentence, numWords) {
    // Split the sentence into an array of words
    var words = sentence.split(" ");
  
    // Ensure the number of words requested is not greater than the total number of words
    numWords = Math.min(numWords, words.length);
  
    // Reduce the array of words to the specified number of words
    var reducedWords = words.slice(0, numWords);
  
    // Join the reduced words back into a sentence
    var reducedSentence = reducedWords.join(" ");
  
    // Return the reduced sentence
    return reducedSentence;
  }
  
  // Example usage
  // var sentence = "This is a sample sentence for demonstration purposes.";
  // var numWords = 4;
  // var reducedSentence = reduceSentenceToWords(sentence, numWords);
  
  // console.log(reducedSentence);
  


  return (
    <ObjectCardContainer onClick={onNavigateHandler}>
      <BackgroundImage imageUrl={`${API_URL}/static/images/object/${_id}.png`} />
      <Body>
        <h2>{soName}</h2>
        <p>{ reduceSentenceToWords(summary,20) }...</p>
        <span>Operator: { operator }</span>
        <p>See Details</p>
      </Body>
    </ObjectCardContainer>
  )
}

export default ProjectCard
