import styled from 'styled-components';
// import { css } from 'styled-components';

// const shrinkLabelStyles = css`
//   font-size: 12px;
//   color: ${mainColor};
// `;

const subColor = 'gray';

export const FormInputLabel= styled.label`
  color: ${subColor};
  font-size: 14px;
  font-weight: normal;
  margin-left: 20px;
 
`;

export const Input = styled.input`
  background: none;
  color: ${subColor};
  font-size: 12px;
  padding: 10px;
  display: flex;
  width: 95%;
  border-radius: 4px;
  border: 1px solid ${subColor};
  margin:10px 20px;
  
  &.w-100{
    width: 100vh;
  }
  &.w-75{
    width: 75vh;
  }
  &.w-50{
    width: 50vh;
  }
  &.w-40{
    width: 40vh;
  }
  &.w-25{
    width: 25vh;
  }

`;

export const TextArea = styled.textarea`
  background: none;
  color: ${subColor};
  font-size: 12px;
  padding: 10px;
  display: flex;
  width: 95%;
  height: 25vh;
  border-radius: 4px;
  border: 1px solid ${subColor};
  margin:10px 20px;
  
  &.w-100{
    width: 100vh;
  }
  &.w-75{
    width: 75vh;
  }
  &.w-50{
    width: 50vh;
  }
  &.w-40{
    width: 40vh;
  }
  &.w-25{
    width: 25vh;
  }

`;

export const Select = styled.select`
  background: none;
  color: ${subColor};
  font-size: 12px;
  padding: 10px 10px 10px 5px;
  display: flex;
  width: 95%;
  border-radius: 4px;
  border: 1px solid ${subColor};
  margin:10px 20px;
  
  &.w-100{
    width: 100vh;
  }
  &.w-75{
    width: 75vh;
  }
  &.w-50{
    width: 50vh;
  }
  &.w-40{
    width: 40vh;
  }
  &.w-25{
    width: 25vh;
  }

`;

export const Group = styled.div`
  display:flex;
  flex-direction:column;
  width:auto;
  height:auto;
  margin:10px 0;

  
  input[type='password'] {
    letter-spacing: 0.3em;
  }
`;