import React from 'react';
import NavBar from '../../components/navbar/mainNav.component';
import SignInForm from '../../components/sign-in-form/sign-in.component';
import Footer from '../../components/footer/footer.component';
import logo from '../../assets/icon-logo.png';

import {
    SignInDiv,
    ImgDiv
} from './signIn.styles'

const SignUp = () => {
    return (
        <div>
            <NavBar mode='light' />
            <SignInDiv>
                <ImgDiv>
                    <img src={logo} alt="logo" />
                </ImgDiv>
                <SignInForm />
            </SignInDiv>

            <Footer bottom='fixed' />
        </div>

    )
}

export default SignUp;