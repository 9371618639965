import styled from 'styled-components';

export const SignInContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 30vw;
  margin: auto 20px;

  .link{
    display: flex;
    text-decoration: none;
    justify-content: end;
    color: #3E4197;

  }

  h2 {
    margin: 10px 0;
  }

  p{
    text-align: center;
    color: gray;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;