import styled from 'styled-components'

export const BackgroundImage = styled.div`
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-image: ${({ imageUrl }) => `url(${imageUrl})`};
`

export const Body = styled.div`
  height: 100%;
  width: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: 1px solid black;
  background-color: white;
  opacity: 0.7;
  position: absolute;

  h2 {
    font-size: klarge;
    font-weight: bold;
    margin: 0 6px 0;
    color: #0b0b0b;
    text-transform: uppercase;
  }
  p {
    width: 100%;
    font-size: 14px;
    color: #0b0b0b;
  }
  span{
    margin-top: 10%;
    font-weight: bold;
  }

`

export const ObjectCardContainer = styled.div`
  position: relative;
  width: 20vw;
  height: 400px;
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid black;
  margin: 10px;
  overflow: hidden;
  &:first-child {
    margin-right: 7.5px;
  }
  &:hover {
    cursor: pointer;
    ${BackgroundImage} {
      transform: scale(1.1);
      transition: transform 6s cubic-bezier(0.25, 0.45, 0.45, 0.95);
    }
    ${Body} {
      opacity: 0.9;
    }
  }
`

