import styled from 'styled-components'

export const ColTitle = styled.h3`
  font-size: 2rem;
  font-weight: bold;
  margin-left: 50px;
`;

export const InputColumns = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border-bottom: 1px dashed gray;
  padding-bottom: 50px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ObjectsContainer = styled.div`
  display: flex;
  flex-direction:column;
  padding: 10%;
`;

export const ErrMsg = styled.h2`
  margin: 30px;
  color: gray;
  font-size: large;
`