import {
  Group,
  Input,
  FormInputLabel,
  Select,
  TextArea
} from './form-input.styles';

const FormInput = ({ label, ...otherProps }) => {
  if(otherProps.type==='text'||otherProps.type==='email'||otherProps.type==='datetime-local'||otherProps.type=='password'){
    return (
      <Group>
      {label && (
        <FormInputLabel>
          {label}
        </FormInputLabel>
        )}
        <Input onChange={otherProps.handleChange} {...otherProps} type={otherProps.type} className={`w-${otherProps.width}`} />
      </Group>
    );
  }else if(otherProps.type==='textarea'){
    return (
      <Group>
      {label && (
        <FormInputLabel>
          {label}
        </FormInputLabel>
        )}
        <TextArea onChange={otherProps.handleChange} {...otherProps} type={otherProps.type} className={`w-${otherProps.width}`}></TextArea>
      </Group>
    );
  }else if(otherProps.type==='select'){
    return(
      <Group>
      {label && (
        <FormInputLabel>
          {label}
        </FormInputLabel>
        )}
        <Select onChange={otherProps.handleChange} {...otherProps} className={`w-${otherProps.width}`}> 
          <option disabled>{otherProps.placeholder}</option>
          {otherProps.options.map(item=><option value={item}>{item}</option>)}
        </Select>
      </Group>
      )
  }else if(otherProps.type==='file'){
    return (
      <Group>
      {label && (
        <FormInputLabel>
          {label}
        </FormInputLabel>
        )}
        <Input onChange={otherProps.handleChange} {...otherProps} type={otherProps.type} ref={otherProps.ref} className={`w-${otherProps.width}`} placeholder='Click to add image'/>
      </Group>
    );
  }
};

export default FormInput;