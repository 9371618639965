import { useNavigate } from 'react-router-dom'
import AuthService from '../../auth/auth_user_utils';
import {
  BackgroundImage,
  Body,
  ObjectCardContainer
} from './objectCard.styles'

const API_URL = "/api";

const ProjectCard = ({ data }) => {
  const { soName, _id, summary, operator,published } = data;
  const navigate = useNavigate();

  const onUpdateHandler = () => navigate(`/admin-page-restricted/project/${_id}`);
  const onPublishHandler = () => {
    fetch(`${API_URL}/admin-projects/publish-toggle/${_id}/`,
    {
        method: "PUT",
        headers:{
            'Authorization': AuthService.getCurrentUser()
        }
    })
    .then(response => {
      if(response.status==201){
        window.location.reload()
      }
    })
  };
  const onDeleteHandler = () => {
    fetch(`${API_URL}/admin-projects/${_id}/`,
      {
        method: "DELETE",
        headers:{
            'Authorization': AuthService.getCurrentUser()
        }
    })
    .then(response => {
      if(response.status==204){
        window.location.reload()
      }
    })
  };

  return (
    <ObjectCardContainer>
      <BackgroundImage imageUrl={`${API_URL}/static/images/object/${_id}.png`} />
      <Body>
        <h2>{soName}</h2>
        <span>Operator: { operator }</span>
      </Body>
      <button onClick={onUpdateHandler}>update</button>
      <button onClick={onPublishHandler}>{published?"UnPublish":"Publish"}</button>
      <button onClick={onDeleteHandler}>delete</button>
    </ObjectCardContainer>
  )
}

export default ProjectCard
