import { MyButton } from './button.styles.jsx';

const BUTTON_TYPE_CLASSES = {
  google: 'google-sign-in',
  inverted: 'inverted',
  signInUp:'default',
  login:'inverted'
};

const Button = ({ children, buttonType, ...otherProps }) => {
  return (
    <MyButton
      className={`button-container ${BUTTON_TYPE_CLASSES[buttonType]}`}
      {...otherProps}
    >
      {children}
    </MyButton>
  );
};

export default Button;