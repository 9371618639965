import styled from 'styled-components'

export const MyButton = styled.button`
    &.button-container{
        &.google-sign-in{
            position: relative;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 100%;
            border: 1px solid #443F3F;
            border-radius: 2px;
            background: none;
            margin: 30px 0;
            font-weight: bold;
        
            img{
                position: relative;
                width: 20px;
                height: auto;
                margin: 5px;
            }
        }

        &.default{
            position: relative;
            padding: 10px;
            width: 100%;
            border: none;
            border-radius: 2px;
            background: #3E4197;
            color: white;
            margin: 30px 0;
            font-weight: bold; 
        }
    }
    
`