import styled from 'styled-components'
//import { Link } from 'react-router-dom'

export const HomeContainer = styled.div`
   height:80vh;
   width:100vw;
`;
export const MainSection = styled.div`
   height:100%;
   width:100%;
   display:flex;
   flex-direction:row;
   align-items:center;
   justify-content:center;
   padding-top: 100px;
   

`;
export const DivContainer = styled.div`
   height: 100%;
   width: 50%;
   margin: 150px 0 0 40px;
   padding: 10px;

   h2{
      font-size: 2rem;
      margin: 5px;
      font-weight: 400;
   }
   h1{
      font-size: 4rem;
      margin: 5px 0px;
      width: 80%;
   }
   p{
      font-size: 1rem;
      margin: 5px;
      .link{
         color: #3E4197;
      }
   }
   @media (max-width: 660px) {
      width: 100%; 
      h2{
         font-size: 1rem;
      }
      h1{
         font-size: 2rem;
      }
      p{
         font-size: 0.8rem;
         .link{
            color: white;
         }
      }
    }
`;

export const ImageContainer = styled.div`
   width:50%;
   height:100%;
   img{
      position: absolute;
      right: 10vw;
      bottom: 50px;
      height: 35vw;
      width: 35vw;
      border-radius: 50%;
      object-fit: cover;
   }
   @media (max-width: 660px){
      img{
         height: 65vw;
         width: 65vw;
      }
   }
      
`;

export const DivCircle = styled.div`
   position: absolute;
   top: -30%;
   right: -20%;
   height: 120vh;
   width: 80vw;
   background-color: #3E4197;
   border: none;
   border-radius: 50%;
   pointer-events: none;
   z-index: -1;

   @media (max-width: 660px) {
      top: -30%;
      right: -20%;
      height: 120vh;
      width: 120vh;
   }

`;

