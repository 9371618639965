import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import Footer from '../../components/footer/footer.component'

import NavBar from '../../components/navbar/mainNav.component'

import ObjectCard from '../../components/object-card/objectCard.component'
import { ObjectsContainer, Heading, SubHeading, SummarySection } from './spaceObjects.styles'

import { generateSummaryAllPDF } from '../../templates/projects/generateReportPdf'

import listIcon from '../../assets/list.png'
import cardIcon from '../../assets/cards.png'

const ExportButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: #3E4197;
  font-size: medium;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 3px;
`

const ProjectData = ({data,search})=>{ 
  if(search&&search.length>0){
    let Arr = []
    {Array.isArray(data)&&data.map((item,i)=>{
      if(item.function&&item.function.toLowerCase().includes(search.toLowerCase())||item.name&&item.name.toLowerCase().includes(search.toLowerCase())||item.summary&&item.summary.toLowerCase().includes(search.toLowerCase())||item.owner&&item.owner.toLowerCase().includes(search.toLowerCase())){
        Arr.push(<ObjectCard key={i} data={item} />)
      }
    })}
    
    // function,name,summary,owner
    return(Arr.length>0?Arr:'No Results')

  }else{
    let Arr = []
    {Array.isArray(data)&&data.map((item,i)=>{
      Arr.push(<ObjectCard key={i} data={item} />)
    })}
    
    return(Arr)
  }
}

const TableData = ({data,search})=>{
  let Arr = []
  if(search&&search.length>0){
    {Array.isArray(data)&&data.map((item,i)=>{
      if(item.function&&item.function.toLowerCase().includes(search.toLowerCase())||item.name&&item.name.toLowerCase().includes(search.toLowerCase())||item.summary&&item.summary.toLowerCase().includes(search.toLowerCase())||item.owner&&item.owner.toLowerCase().includes(search.toLowerCase())){
        Arr.push(
        <tr onClick={()=>window.location.href=`/space-objects/${item._id}`}>
          <td>{item.soName}</td>
          <td>{item.cospar}</td>
          <td>{item.owner}</td>
          <td>{item.operator}</td>
          <td>{item.launchVehicle}</td>
        </tr>
        )
      }
    })}
  }else{
    {Array.isArray(data)&&data.map((item,i)=>{
      Arr.push(
      <tr onClick={()=>window.location.href=`/space-objects/${item._id}`}>
        <td>{i+1}</td>
        <td>{item.cospar}</td>
        <td>{item.soName}</td>
        <td>{item.launchDate}</td>
        <td>{item.launchLocation}</td>
        <td>{item.launchVehicle}</td>
        <td>Kenya</td>
        <td>{item.owner}</td>
        <td>{item.operator}</td>
        <td>{item.orbitalPosition}</td>
        <td>{item.regStatus}</td>
        <td>{item.soStatus}</td>
      </tr>
    )
    })}
  }
    return(
      <SummarySection>
        <ExportButton onClick={()=>generateSummaryAllPDF(data)}>Export content to PDF</ExportButton>
        <table>
          <tbody className="tableFull">
            <tr>
              <th>#</th>
              <th>International Identification</th>
              <th>Space Object Name</th>
              <th>Launch Date</th>
              <th>Launch Site</th>
              <th>Launch Vehicle</th>
              <th>State</th>
              <th>Owner</th>
              <th>Operator</th>
              <th>Type of Orbit</th>
              <th>Registration Status</th>
              <th>Space Object Status</th>
            </tr>
            {Arr}
          </tbody>
        </table>
      </SummarySection>
    )
  
}


const SearchBar = styled.input`
    position: absolute;
    top: -50px;
    right: 50px;
    padding: 10px;
`

const SelectBox = styled.select`
  padding: 10px;
`

const SetView = styled.div`
  position: absolute;
  top: -50px;
  left: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  img{
    margin-right: 10px;
    height: 20px;
  }
`

const Projects = () => {

  const [search,setSearch] = useState()
  const [view,setView] = useState("list")
  const [projects,setProjects] = useState()
  
  useEffect(()=>{
    fetch(`/api/projects/`)
    .then(async response => {
      const data = await response.json();

      // check for error response
      if (!response.ok) {
          // get error message from body or default to response statusText
          const error = (data && data.message) || response.statusText;
          console.log(error)
          return Promise.reject(error);
      }
      setProjects(data['data'])
    })
    .catch(error => {
        // this.setState({ errorMessage: error.toString() });
        console.error('There was an error!', error);
    });
  },[])

  return (
    <div>
      <NavBar mode='light'/>
      <Heading>Registered Space Objects</Heading>
      <SubHeading>A Register of Objects Launched into Outer Space by the Republic of Kenya</SubHeading>
      <ObjectsContainer>
        <SearchBar placeholder="Search" value={search} onChange={(e)=>setSearch(e.target.value)}/>

        <SetView>
          <img src={view=='list'?listIcon:cardIcon}/>
          <SelectBox onChange={(e)=>setView(e.target.value)}>
            <option value="list">list</option>
            <option value="card">card</option>
          </SelectBox>
        </SetView>
        <h3 id="summaryReport">
          Kenya Space Objects
        </h3>
        {
          view=='list'?<TableData data={projects} search={search}/>:""
        }
        {
          view=='card'?<ProjectData data={projects} search={search}/>:""
        }
        {/* {search&&search.length>0?<SearchResults data={projects} search={search}/>:<ProjectData data={projects}/>}  */}
      </ObjectsContainer>
      <Footer bottom='relative'/>
    </div>
  )
}

export default Projects