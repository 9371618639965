const API_URL = "/api";

class AuthService {
  login(email, password) {
    return fetch(
        `${API_URL}/users/login/`, {
        method: 'POST',
        headers: {
            'content-type':'application/json'
        },
        body: JSON.stringify({'email':email,'password':password}),
      })
      .then(response => {
        if(response.status==200){
            return response.json()
        }else{
            return 400
        }
      }).then(response => {
        if (response.data.token) {
            sessionStorage.setItem("ksa_admin_user", JSON.stringify(response.data.token));
            return 200
        }
    });
  }

  logout() {
    sessionStorage.removeItem("ksa_admin_user");
    window.location.reload()
  }

//   register(username, email, password) {
//     return axios.post(API_URL + "/api/auth/signup", {
//       username,
//       email,
//       password
//     });
//   }

  getCurrentUser() {
    return JSON.parse(sessionStorage.getItem('ksa_admin_user'));;
  }
}

export default new AuthService();
