import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import FormInput from '../../components/form-input/form-input.component';
import Button from '../../components/button/button.component';
import NavBar from '../../components/navbar/mainNav.component'
import Footer from '../../components/footer/footer.component'

import AuthService from '../../auth/auth_user_utils';

import {
     ObjectsContainer,
     ColTitle,
     InputColumns,
     ButtonsContainer,
     ErrMsg
} from './updateObjects.styles'

const btnSubmit =  {background:'#3E4197',padding:'15px',margin:'20px', border:'none',color:'white'}

const API_URL = "http:backend:5000/api";

const UpdateProjects = () => {
    const template = {
        coverImage:'',
        name:'',
        summary:'',
        affiliation:'',
        email:'',
        mobile:'',
        soName:'',
        owner:'',
        ownerWebsite:'',
        operator:'',
        contractor:'',
        purpose:'',
        decay:'',
        launchDate:'',
        launchState:'',
        launchLocation:'',
        launchContractor:'',
        launchVehicle:'',
        power:'',
        propulsion:'',
        mass:'',
        lifetime:'',
        orbitalPosition:'',
        cospar:'',
        unoosaLink: '',
        soStatus:'',
        regStatus:'',
        statusInfo:'',
        ctNo:'',
        nodal:'',
        inclination:'',
        apogee:'',
        perigee:''
    }

    const [submitClick,setSubmitClick] = useState(false)
    const [body,setBody] = useState(template)
    const [msg,setMsg] = useState("")
    
    const handleChange = (e)=>{
        setBody(curr=>{
            if(e.target.type==='file'){
                return(
                    {...curr,[e.target.name]:e.target.files[0]}
                )
            }else{
                return(
                    {...curr,[e.target.name]:e.target.value}
                )
            }
        })
    }
    const [user, setUser]= useState()
    // let { url } = useRouteMatch();
    let {name} = useParams()

    useEffect(()=>{
        if(sessionStorage.getItem('ksa_admin_user')){
            setUser(sessionStorage.getItem('ksa_admin_user'))
        }else{
            window.location.href="/admin-signin"
        }
        // GET request using fetch inside useEffect React hook
        fetch(`/api/admin-projects/${name}/`,
        {
            methods: "GET",
            headers:{
                'Content-Type': 'application/json',
                'Authorization': AuthService.getCurrentUser()
            }
        })
        .then(response => response.json())
        .then(data => setBody(data.data));
    },[])

    useState(()=>{console.log(body)},[body])
    const handleSubmit = (e)=>{
        setSubmitClick(true)
        if(body.coverImage===''||body.name===''||body.decay===''||body.affiliation===''||body.email===''||body.mobile===''||body.soName===''||body.owner===''|| body.ownerWebsite==='' ||body.operator===''||body.contractor===''||body.purpose===''||body.launchDate===''||body.launchLocation===''||body.launchContractor===''||body.launchVehicle===''||body.power===''||body.propulsion===''||body.mass===''||body.lifetime===''||body.orbitalPosition===''||body.cospar===''||body.unoosaLink===''||body.soStatus===''||body.regStatus===''||body.statusInfo===''||body.ctNo===''||body.nodal===''||body.inclination===''||body.apogee===''||body.perigee===''){
                setMsg("Check fields")
        }else{
            let formData = new FormData()
            for(let item in body){
                formData.append(item, body[item])
            }

            setMsg('Sending...')
            
            fetch(`/api/admin-projects/${name}/`, {
                method: 'PUT',
                headers:{
                    'Authorization': AuthService.getCurrentUser()
                },
                body: formData,
                })        
                .then((response) => {
                if (response.status !== 201) {
                    throw new Error(response.statusText);
                } 
                return response.json();
                })
                .then(() => {
                setMsg("Successful Update");
                })
                .catch((err) => {
                setMsg(err.toString());
                });
            }
    }  

    const errStyle = {
        border: '1px solid red'
    }

    return (
        <div>
            <NavBar mode='light'/>
            <ObjectsContainer>
                <h2>Add Space Object to Registry</h2>
                    <ColTitle>Applicant Details / Contact Person</ColTitle>
                    <InputColumns>
                        <FormInput
                            style={submitClick&&body.name===''?errStyle:{}}
                            width='40'
                            label='Name'
                            type='text'
                            require
                            name='name'
                            value={body.name}
                            handleChange={handleChange}
                            placeholder='Jane Doe'
                            />

                        <FormInput
                            style={submitClick&&body.affiliation===''?errStyle:{}}
                            width='40'
                            label='Affiliation'
                            type='text'
                            require
                            name='affiliation'
                            value={body.affiliation}
                            handleChange={handleChange}
                            placeholder='Project Manager at Space Object LTD'
                            />

                        <FormInput
                            style={submitClick&&body.email===''?errStyle:{}}
                            width='40'
                            label='Email'
                            type='email'
                            name='email'
                            value={body.email}
                            handleChange={handleChange}
                            placeholder='jane.doe@email.com'
                            />

                        <FormInput
                            style={submitClick&&body.mobile===''?errStyle:{}}
                            width='40'
                            label='mobile'
                            type='text'
                            require
                            name='mobile'
                            value={body.mobile}
                            handleChange={handleChange}
                            placeholder='+25400123123'
                            />
                    </InputColumns>

                    <ColTitle>Space Object Details</ColTitle>
                    <InputColumns>
                        <FormInput
                            style={submitClick&&body.soName===''?errStyle:{}}
                            width='40'
                            label='Space Object Name'
                            type='text'
                            require
                            name='soName'
                            value={body.soName}
                            handleChange={handleChange}
                            placeholder='SP-Name'
                            />

                        <FormInput
                            style={submitClick&&body.summary===''?errStyle:{}}
                            width='100'
                            label='Space Object Summary'
                            type='textarea'
                            require
                            name='summary'
                            value={body.summary}
                            handleChange={handleChange}
                            placeholder='A brief summary of this Space object in less than 400 words'
                            />
                        
                        <FormInput
                            style={submitClick&&body.owner===''?errStyle:{}}
                            width='40'
                            label='Owner'
                            type='text'
                            name='owner'
                            value={body.owner}
                            handleChange={handleChange}
                            placeholder='Space Object LTD'
                            />

                        <FormInput
                            style={submitClick&&body.ownerWebsite===''?errStyle:{}}
                            width='40'
                            label='Owner Website'
                            type='text'
                            name='ownerWebsite'
                            value={body.ownerWebsite}
                            handleChange={handleChange}
                            placeholder='https://www.Space-Object-LTD.co.ke'
                            />

                        <FormInput
                            style={submitClick&&body.operator===''?errStyle:{}}
                            width='40'
                            label='Operator'
                            type='text'
                            require
                            name='operator'
                            value={body.operator}
                            handleChange={handleChange}
                            placeholder='Operator LTD'
                            />

                        <FormInput
                            style={submitClick&&body.contractor===''?errStyle:{}}
                            width='40'
                            label='Contractors'
                            type='text'
                            require
                            name='contractor'
                            value={body.contractor}
                            handleChange={handleChange}
                            placeholder='Operator LTD, SpaceX, EcoSpace'
                            />

                        <FormInput
                            style={submitClick&&body.purpose===''?errStyle:{}}
                            width='40'
                            label='General function'
                            type='text'
                            name='purpose'
                            value={body.purpose}
                            handleChange={handleChange}
                            placeholder='This satellite is used for Telecommunications'
                            />
                        
                        <FormInput
                            style={submitClick&&body.soStatus===''?errStyle:{}}
                            width='40'
                            label='Space Object Status'
                            type='select'
                            options={["Operational",
                            "De-Orbited",
                            "Damaged"
                            ]}
                            value={body.soStatus}
                            name='soStatus'
                            handleChange={handleChange}
                            />

                        <FormInput
                            style={submitClick&&body.statusInfo===''?errStyle:{}}
                            width='100'
                            label='Additional Information'
                            type='textarea'
                            require
                            value={body.statusInfo}
                            name='statusInfo'
                            handleChange={handleChange}
                            placeholder='Share more information on the current status of the space object'
                            />
                    </InputColumns>

                    <ColTitle>International Registration Details</ColTitle>
                    <InputColumns>
                        <FormInput
                            style={submitClick&&body.cospar===''?errStyle:{}}
                            width='40'
                            label='International Designation / COSPAR'
                            type='text'
                            require
                            name='cospar'
                            value={body.cospar}
                            handleChange={handleChange}
                            placeholder='19-11V'
                            />

                        <FormInput
                            style={submitClick&&body.unoosaLink===''?errStyle:{}}
                            width='40'
                            label='Link to the space object registered with UNOOSA'
                            type='text'
                            require
                            name='unoosaLink'
                            value={body.unoosaLink}
                            handleChange={handleChange}
                            placeholder='Type `NA` if not applicable'
                            />

                        <FormInput
                            style={submitClick&&body.regStatus===''?errStyle:{}}
                            width='40'
                            label='Registration Status'
                            type='select'
                            options={["Pending",
                            "Registered"
                            ]}
                            value={body.regStatus}
                            name='regStatus'
                            handleChange={handleChange}
                            />

                    </InputColumns>
                    <ColTitle>Technical Details</ColTitle>
                    <InputColumns>
                        <FormInput
                            style={submitClick&&body.ctNo===''?errStyle:{}}
                            width='40'
                            label='Catalogue Number'
                            type='text'
                            name='ctNo'
                            value={body.ctNo}
                            handleChange={handleChange}
                            placeholder='10992'
                            />

                        <FormInput
                            style={submitClick&&body.power===''?errStyle:{}}
                            width='40'
                            label='Power'
                            type='text'
                            name='power'
                            value={body.power}
                            handleChange={handleChange}
                            placeholder='Solar, Batteries'
                            />

                        <FormInput
                            style={submitClick&&body.propulsion===''?errStyle:{}}
                            width='40'
                            label='Propulsion'
                            type='text'
                            name='propulsion'
                            value={body.propulsion}
                            handleChange={handleChange}
                            placeholder='Chemical Propellant, Oxindant oxygen/liquid hydrogen'
                            />

                        <FormInput
                            style={submitClick&&body.mass===''?errStyle:{}}
                            width='40'
                            label='Mass'
                            type='text'
                            name='mass'
                            value={body.mass}
                            handleChange={handleChange}
                            placeholder='1.5 Kg'
                            />

                        <FormInput
                            style={submitClick&&body.lifetime===''?errStyle:{}}
                            width='40'
                            label='Lifetime'
                            type='text'
                            name='lifetime'
                            value={body.lifetime}
                            handleChange={handleChange}
                            placeholder='5 years'
                            />

                        <FormInput
                            style={submitClick&&body.decay===''?errStyle:{}}
                            width='40'
                            label='Date of Decay / Reenrty / Reorbit'
                            type='datetime-local'
                            name='decay'
                            value={body.decay}
                            handleChange={handleChange}
                            placeholder=''
                            />
                    </InputColumns>
                    
                    <ColTitle>Launch Details</ColTitle>
                    <InputColumns>
                        <FormInput
                            style={submitClick&&body.launchDate===''?errStyle:{}}
                            width='40'
                            label='Date of Launch'
                            type='datetime-local'
                            require
                            name='launchDate'
                            value={body.launchDate}
                            handleChange={handleChange} 
                            />
                        
                        <FormInput
                            style={submitClick&&body.launchState===''?errStyle:{}}
                            width='40'
                            label='Launching State'
                            type='select'
                            options={["Afghanistan",
                            "Albania",
                            "Algeria",
                            "American Samoa",
                            "Andorra",
                            "Angola",
                            "Anguilla",
                            "Antarctica",
                            "Antigua and Barbuda",
                            "Argentina",
                            "Armenia",
                            "Aruba",
                            "Australia",
                            "Austria",
                            "Azerbaijan",
                            "Bahamas (the)",
                            "Bahrain",
                            "Bangladesh",
                            "Barbados",
                            "Belarus",
                            "Belgium",
                            "Belize",
                            "Benin",
                            "Bermuda",
                            "Bhutan",
                            "Bolivia (Plurinational State of)",
                            "Bonaire, Sint Eustatius and Saba",
                            "Bosnia and Herzegovina",
                            "Botswana",
                            "Bouvet Island",
                            "Brazil",
                            "British Indian Ocean Territory (the)",
                            "Brunei Darussalam",
                            "Bulgaria",
                            "Burkina Faso",
                            "Burundi",
                            "Cabo Verde",
                            "Cambodia",
                            "Cameroon",
                            "Canada",
                            "Cayman Islands (the)",
                            "Central African Republic (the)",
                            "Chad",
                            "Chile",
                            "China",
                            "Christmas Island",
                            "Cocos (Keeling) Islands (the)",
                            "Colombia",
                            "Comoros (the)",
                            "Congo (the Democratic Republic of the)",
                            "Congo (the)",
                            "Cook Islands (the)",
                            "Costa Rica",
                            "Croatia",
                            "Cuba",
                            "Curaçao",
                            "Cyprus",
                            "Czechia",
                            "Côte d'Ivoire",
                            "Denmark",
                            "Djibouti",
                            "Dominica",
                            "Dominican Republic (the)",
                            "Ecuador",
                            "Egypt",
                            "El Salvador",
                            "Equatorial Guinea",
                            "Eritrea",
                            "Estonia",
                            "Eswatini",
                            "Ethiopia",
                            "Falkland Islands (the) [Malvinas]",
                            "Faroe Islands (the)",
                            "Fiji",
                            "Finland",
                            "France",
                            "French Guiana",
                            "French Polynesia",
                            "French Southern Territories (the)",
                            "Gabon",
                            "Gambia (the)",
                            "Georgia",
                            "Germany",
                            "Ghana",
                            "Gibraltar",
                            "Greece",
                            "Greenland",
                            "Grenada",
                            "Guadeloupe",
                            "Guam",
                            "Guatemala",
                            "Guernsey",
                            "Guinea",
                            "Guinea-Bissau",
                            "Guyana",
                            "Haiti",
                            "Heard Island and McDonald Islands",
                            "Holy See (the)",
                            "Honduras",
                            "Hong Kong",
                            "Hungary",
                            "Iceland",
                            "India",
                            "Indonesia",
                            "Iran (Islamic Republic of)",
                            "Iraq",
                            "Ireland",
                            "Isle of Man",
                            "Israel",
                            "Italy",
                            "Jamaica",
                            "Japan",
                            "Jersey",
                            "Jordan",
                            "Kazakhstan",
                            "Kenya",
                            "Kiribati",
                            "Korea (the Democratic People's Republic of)",
                            "Korea (the Republic of)",
                            "Kuwait",
                            "Kyrgyzstan",
                            "Lao People's Democratic Republic (the)",
                            "Latvia",
                            "Lebanon",
                            "Lesotho",
                            "Liberia",
                            "Libya",
                            "Liechtenstein",
                            "Lithuania",
                            "Luxembourg",
                            "Macao",
                            "Madagascar",
                            "Malawi",
                            "Malaysia",
                            "Maldives",
                            "Mali",
                            "Malta",
                            "Marshall Islands (the)",
                            "Martinique",
                            "Mauritania",
                            "Mauritius",
                            "Mayotte",
                            "Mexico",
                            "Micronesia (Federated States of)",
                            "Moldova (the Republic of)",
                            "Monaco",
                            "Mongolia",
                            "Montenegro",
                            "Montserrat",
                            "Morocco",
                            "Mozambique",
                            "Myanmar",
                            "Namibia",
                            "Nauru",
                            "Nepal",
                            "Netherlands (the)",
                            "New Caledonia",
                            "New Zealand",
                            "Nicaragua",
                            "Niger (the)",
                            "Nigeria",
                            "Niue",
                            "Norfolk Island",
                            "Northern Mariana Islands (the)",
                            "Norway",
                            "Oman",
                            "Pakistan",
                            "Palau",
                            "Palestine, State of",
                            "Panama",
                            "Papua New Guinea",
                            "Paraguay",
                            "Peru",
                            "Philippines (the)",
                            "Pitcairn",
                            "Poland",
                            "Portugal",
                            "Puerto Rico",
                            "Qatar",
                            "Republic of North Macedonia",
                            "Romania",
                            "Russian Federation (the)",
                            "Rwanda",
                            "Réunion",
                            "Saint Barthélemy",
                            "Saint Helena, Ascension and Tristan da Cunha",
                            "Saint Kitts and Nevis",
                            "Saint Lucia",
                            "Saint Martin (French part)",
                            "Saint Pierre and Miquelon",
                            "Saint Vincent and the Grenadines",
                            "Samoa",
                            "San Marino",
                            "Sao Tome and Principe",
                            "Saudi Arabia",
                            "Senegal",
                            "Serbia",
                            "Seychelles",
                            "Sierra Leone",
                            "Singapore",
                            "Sint Maarten (Dutch part)",
                            "Slovakia",
                            "Slovenia",
                            "Solomon Islands",
                            "Somalia",
                            "South Africa",
                            "South Georgia and the South Sandwich Islands",
                            "South Sudan",
                            "Spain",
                            "Sri Lanka",
                            "Sudan (the)",
                            "Suriname",
                            "Svalbard and Jan Mayen",
                            "Sweden",
                            "Switzerland",
                            "Syrian Arab Republic",
                            "Taiwan",
                            "Tajikistan",
                            "Tanzania, United Republic of",
                            "Thailand",
                            "Timor-Leste",
                            "Togo",
                            "Tokelau",
                            "Tonga",
                            "Trinidad and Tobago",
                            "Tunisia",
                            "Turkey",
                            "Turkmenistan",
                            "Turks and Caicos Islands (the)",
                            "Tuvalu",
                            "Uganda",
                            "Ukraine",
                            "United Arab Emirates (the)",
                            "United Kingdom of Great Britain and Northern Ireland (the)",
                            "United States Minor Outlying Islands (the)",
                            "United States of America (the)",
                            "Uruguay",
                            "Uzbekistan",
                            "Vanuatu",
                            "Venezuela (Bolivarian Republic of)",
                            "Viet Nam",
                            "Virgin Islands (British)",
                            "Virgin Islands (U.S.)",
                            "Wallis and Futuna",
                            "Western Sahara",
                            "Yemen",
                            "Zambia",
                            "Zimbabwe",
                            "Åland Islands"
                            ]}
                            name='launchState'
                            value={body.launchState}
                            handleChange={handleChange}
                            placeholder='San Marco Launch platfrom Malindi'
                            />

                        <FormInput
                            style={submitClick&&body.launchLocation===''?errStyle:{}}
                            width='40'
                            label='Launch Location'
                            type='select'
                            options={[
                                "Alcantara Launch CenterMaranhao , Brazil",
                                "Andoya Space CenterAndoya , Norway",
                                "Baikonur CosmodromeBaikonur , Kazakhstan",
                                "Cape Canaveral Air Force StationCocoa Beach FL, USA",
                                "Cecil Field SpaceportJacksonville FL, USA",
                                "Colorado Air & Space PortWatkins CO, USA",
                                "Corn RanchVan Horn TX, USA",
                                "Guiana Space CentreKourou , French Guiana",
                                "Houston SpaceportHouston TX, USA",
                                "International Space Station, ISS",
                                "Jiuquan Satellite Launch CenterJiuquan , Mongolia",
                                "Kapustin YarAstrakhan Oblast , Russia",
                                "Kennedy Space CenterMerritt Island FL, USA",
                                "Luigi Broglio Space CenterMalinda , Kenya",
                                "Mid-Atlantic Regional SpaceportWallops Island VA, USA",
                                "Midland Air & Space PortMidland TX, USA",
                                "Mojave Air and Space PortMojave CA, USA",
                                "Naro Space CenterOuter Naro Island , South Korea",
                                "Oklahoma Air & Space PortBurns Flat OK, USA",
                                "Pacific Spaceport ComplexKodiak Island AK, USA",
                                "Plesetsk CosmodromeAstrakhan Oblast , Russia",
                                "Rocket Lab Launch Complex 1Mahia Peninsula , New Zealand",
                                "Satish Dhawan Space CentreSriharikota , India",
                                "Spaceport AmericaT or C NM, USA",
                                "SpaceX Boca ChicaBoca Chica TX, USA",
                                "Taiyuan Satellite Launch CenterTaiyuan City , China",
                                "Tanegashima Space CenterTanegashima , Japan",
                                "Uchinoura Space CenterKimotsuki , Japan",
                                "Vandenberg Air Force BaseLompoc CA, USA",
                                "Vostochny CosmodromeAmur Oblast , Russia",
                                "Wallops Flight FacilityWallops Island VA, USA",
                                "Wenchang Spacecraft Launch SiteWenchang , China",
                                "Xichang Satellite Launch CenterXichang , China"
                            ]}
                            name='launchLocation'
                            value={body.launchLocation}
                            handleChange={handleChange}
                            placeholder='San Marco Launch platfrom Malindi'
                            />
                            
                            
                        <FormInput
                            style={submitClick&&body.launchContractor===''?errStyle:{}}
                            width='40'
                            label='Launch Contractor'
                            type='select'
                            options={[
                                "Alcantara Launch CenterMaranhao , Brazil",
                                "Andoya Space CenterAndoya , Norway",
                                "Baikonur CosmodromeBaikonur , Kazakhstan",
                                "Cape Canaveral Air Force StationCocoa Beach FL, USA",
                                "Cecil Field SpaceportJacksonville FL, USA",
                                "Colorado Air & Space PortWatkins CO, USA",
                                "Corn RanchVan Horn TX, USA",
                                "Guiana Space CentreKourou , French Guiana",
                                "Houston SpaceportHouston TX, USA",
                                "Jiuquan Satellite Launch CenterJiuquan , Mongolia",
                                "Kapustin YarAstrakhan Oblast , Russia",
                                "Kennedy Space CenterMerritt Island FL, USA",
                                "Luigi Broglio Space CenterMalinda , Kenya",
                                "Mid-Atlantic Regional SpaceportWallops Island VA, USA",
                                "Midland Air & Space PortMidland TX, USA",
                                "Mojave Air and Space PortMojave CA, USA",
                                "Naro Space CenterOuter Naro Island , South Korea",
                                "Oklahoma Air & Space PortBurns Flat OK, USA",
                                "Pacific Spaceport ComplexKodiak Island AK, USA",
                                "Plesetsk CosmodromeAstrakhan Oblast , Russia",
                                "Rocket Lab Launch Complex 1Mahia Peninsula , New Zealand",
                                "Satish Dhawan Space CentreSriharikota , India",
                                "Spaceport AmericaT or C NM, USA",
                                "SpaceX Boca ChicaBoca Chica TX, USA",
                                "Taiyuan Satellite Launch CenterTaiyuan City , China",
                                "Tanegashima Space CenterTanegashima , Japan",
                                "Uchinoura Space CenterKimotsuki , Japan",
                                "Vandenberg Air Force BaseLompoc CA, USA",
                                "Vostochny CosmodromeAmur Oblast , Russia",
                                "Wallops Flight FacilityWallops Island VA, USA",
                                "Wenchang Spacecraft Launch SiteWenchang , China",
                                "Xichang Satellite Launch CenterXichang , China",
                                "Other"
                              ]}
                            name='launchContractor'
                            value={body.launchContractor}
                            handleChange={handleChange}
                            placeholder='SpaceX'
                            />
                            
                        <FormInput
                            style={submitClick&&body.launchVehicle===''?errStyle:{}}
                            width='40'
                            label='Launch Vehicle'
                            type='text'
                            name='launchVehicle'
                            value={body.launchVehicle}
                            handleChange={handleChange}
                            placeholder='Falcon 9'
                            />
                    </InputColumns>

                    <ColTitle>Orbital Parameters</ColTitle>
                    <InputColumns>
                        <FormInput
                            style={submitClick&&body.orbitalPosition===''?errStyle:{}}
                            width='40'
                            label='Orbital Position'
                            type='text'
                            name='orbitalPosition'
                            value={body.orbitalPosition}
                            handleChange={handleChange}
                            placeholder='Geostationary'
                            />
                        <FormInput
                            style={submitClick&&body.nodal===''?errStyle:{}}
                            width='40'
                            label='Nodal Period'
                            type='text'
                            name='nodal'
                            value={body.nodal}
                            handleChange={handleChange}
                            placeholder='10 hours'
                            />

                        <FormInput
                            style={submitClick&&body.inclination===''?errStyle:{}}
                            width='40'
                            label='Inclination'
                            type='text'
                            require
                            name='inclination'
                            value={body.inclination}
                            handleChange={handleChange}
                            placeholder='2.52 degrees'
                            />

                        <FormInput
                            style={submitClick&&body.apogee===''?errStyle:{}}
                            width='40'
                            label='Apogee'
                            type='text'
                            name='apogee'
                            value={body.apogee}
                            handleChange={handleChange}
                            placeholder='35,788 km'
                            />

                        <FormInput
                            style={submitClick&&body.perigee===''?errStyle:{}}
                            width='40'
                            label='Perigee'
                            type='text'
                            require
                            name='perigee'
                            value={body.perigee}
                            handleChange={handleChange}
                            placeholder='35,798 km'
                            />
                    </InputColumns>
                    
                    <ColTitle>Cover Image</ColTitle>
                    <InputColumns>
                        <img src={ `${API_URL}/static/images/object/${body._id}.png` } alt='Space Object cover'/>
                        <FormInput
                            style={submitClick&&body.coverImage===''?errStyle:{}}
                            width='40'
                            label='Cover Image'
                            type='file'
                            name='coverImage'
                            handleChange={handleChange}
                            />
                    </InputColumns>

                    <ButtonsContainer>
                        <Button onClick={handleSubmit} style={btnSubmit} type='submit'>Make Submission</Button>
                    </ButtonsContainer>
                    {msg!==""?<ErrMsg>{msg}</ErrMsg>:""}
            </ObjectsContainer>
            <Footer/>
        </div>
    )
}
export default UpdateProjects;