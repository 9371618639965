import styled from 'styled-components';


export const SignInDiv = styled.div`
    display:flex;
    flex-direction:row;
    margin: 20vh 0;
    width:100%;
`
export const ImgDiv = styled.div`
    display: flex;
    justify-content: center;
    width: 50%;
    margin: 10px 50px;
    img{
        margin: auto 0;
        width: 40vw;
        height: auto;
    }
`
