import styled from 'styled-components'

export const NavigationContainer = styled.div`
   position: fixed;
   top:0;
   height:10vh;
   width:100%;
   display: flex;
   justify-content: space-between;
   margin: 10px 0;
   padding-left: 25px;
   z-index: 10;
   
   img{
    width:85%;
  }

  
`;
export const LogoContainer = styled.div`
  height:100%;
  width: 100px;
  padding:25px;

`;

export const NavLinks = styled.div`
  width: 70%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 60px;
  
  button{
    cursor: pointer;  
  }
  .link{
    text-decoration:none;
    padding: 10px 15px;
    cursor: pointer;
  }
  .btn-menu{
    text-decoration: none;
    padding: 7px 12px;
    border: none;
    border-radius: 3px;
  }
    
  &.dark{
    .link{
      color: white;
    }
    .btn-menu{
      background: white;
      color: black;
    }
  }

  &.light{
    .link{
      color: black;
    }
    .btn-menu{
      background: #3E4197;
      color: white;
    }
  }
  
  `;

